@import '../page-content/raw_html_content.scss';

@import './general.css';
@import './modal.css';

@import './block_settings.css';
@import './magnific-popup-custom.css';
@import './bootstrap-grid.min.css';
@import './responsive.scss';

@import '../../../editor/app/javascript/styles/layers.css';


.elContentWrapper .elContent {
  @include raw-html-content;
}

// Support for google autocomplete in modals
.pac-container {
  z-index: 100002;
}
